import { Suspense, useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";

export function Component() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const app = useContext(AppContext);
  const api = useApi();

  const [numeros, setNumeros] = useState<any[]>([]);

  useEffect(() => {
    api
      .getNumbers()
      .then((response) => {
        setIsLoading(false);
        setNumeros(response.data);
      })
      .catch((error) => {
        setIsLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: error.message,
        });
      });
  }, [api]);

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">MEUS NÚMEROS</h1>

          <Row className="mb-4">
            <Col xs={12} md={8}>
              <h2 className="fs-4">Olá, {app.user.nome}</h2>
              <p>
                {isLoading
                  ? "Carregando..."
                  : `Você está concorrendo com ${numeros.length} números da sorte.`}
              </p>
            </Col>
            <Col xs={12} md={4}>
              <p className="text-end">
                Os ganhadores serão contatados
                <br />
                de 3 a 7 dias úteis após o sorteio.
                <br />
                Data do Sorteio: 03/08/2024
              </p>
            </Col>
          </Row>

          <Button
            variant="primary"
            className="mb-4"
            onClick={() => navigate("/cupom")}
          >
            <i className="bi bi-plus-lg me-2"></i>
            Adicionar cupom fiscal
          </Button>

          <Row>
            {isLoading && <p>Carregando seus números...</p>}

            {!isLoading && numeros.length === 0 && (
              <p>Nenhum número da sorte cadastrado.</p>
            )}

            {!isLoading &&
              numeros.map((numero) => (
                <Col key={numero.id} xs={12} md={6} lg={4} className="mb-4">
                  <Card className="coupon-card">
                    <Card.Body>
                      <Card.Text>CNPJ: {numero.cnpj}</Card.Text>
                      <Card.Text className="text-danger">
                        N&deg; da sorte: {numero.numero}
                      </Card.Text>
                      <Card.Text className="text-danger">
                        N&deg; cupom fiscal: {numero.cupom}
                      </Card.Text>
                      <Card.Text>
                        Data de compra: {numero.data_compra}
                      </Card.Text>
                      <Card.Text>
                        Data de cadastro: {numero.data_cadastro}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex">
                      {numero.enviou_nota_fiscal === null && (
                        <span className="mx-auto">
                          <i className="bi bi-x-circle-fill text-black me-2"></i>
                          <span>Sem prêmio instantâneo</span>
                        </span>
                      )}

                      {numero.enviou_nota_fiscal === false && (
                        <Button
                          variant="danger"
                          className="mx-auto"
                          size="sm"
                          onClick={() => navigate(`/cupom/${numero.id}`)}
                        >
                          <i className="bi bi-cloud-arrow-up-fill me-2"></i>
                          <span>Enviar nota fiscal</span>
                        </Button>
                      )}

                      {numero.enviou_nota_fiscal === true && (
                        <span className="mx-auto">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Nota fiscal enviada</span>
                        </span>
                      )}
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </Suspense>
  );
}
